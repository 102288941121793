import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import CloseIcon from './icons/CloseIcon'

export interface ModalProps {
  isOpen: boolean
  onClose: () => void
  showCloseButton?: boolean
  backdropCloseEnabled?: boolean
  children?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  showCloseButton = true,
  backdropCloseEnabled = true,
  children = <div className="w-96 h-94"></div>,
}) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeOnEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', closeOnEscape)
    }

    return () => {
      window.removeEventListener('keydown', closeOnEscape)
    }
  }, [isOpen, onClose])

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (
      backdropCloseEnabled &&
      !modalRef.current?.contains(event.target as Node)
    ) {
      onClose()
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div
      role="dialog"
      className="fixed inset-0 z-30 flex items-center justify-center bg-black/50"
      onClick={handleBackdropClick}
      data-testid="backdrop"
    >
      <div
        ref={modalRef}
        className={clsx('bg-white rounded-lg shadow-lg relative', {
          'pt-4 lg:pt-0': showCloseButton,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        {showCloseButton && (
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-2"
            aria-label="Close"
          >
            <CloseIcon
              size={[20, 20]}
              fill="fill-neutral-20-borders-dividers"
            />
          </button>
        )}
        <div>{children}</div>
      </div>
    </div>
  )
}

export default Modal
